import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  time: {
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(1),
    },
  },
  day: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

const Clock = () => {
  const classes = useStyles();

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 6000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className={classes.root}>
      <Typography className={classes.time}>
        {moment(time).format('HH:mm')}
      </Typography>
      <Divider orientation="vertical" flexItem />
      <Typography className={classes.day}>{moment().format('dddd')}</Typography>
    </div>
  );
};

export default Clock;
