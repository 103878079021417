import React from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  boxTransparant: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    padding: theme.spacing(2),
    cursor: 'pointer',
  },
  boxImage: {
    backgroundColor: '#fff',
    padding: theme.spacing(1),
  },
  image: {
    width: '100%',
    height: 'auto',
    aspectRatio: '1/1',
    objectFit: 'cover',
  },
  name: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));

const EntitasItem = ({ data }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box paddingX={1} marginBottom={5}>
      <div
        className={classes.boxTransparant}
        onClick={() => history.push(`/project/${data.slug}`)}
      >
        <div className={classes.boxImage}>
          <img className={classes.image} src={data.logo} alt="logo entitas" />
        </div>
      </div>
      <Typography className={classes.name}>{data.name}</Typography>
    </Box>
  );
};

export default EntitasItem;
