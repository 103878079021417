/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography } from '@material-ui/core';

import Clock from '../Clock';
import Logo from '../../../../assets/images/logo-website.png';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 'auto',
    height: '40px',
    cursor: 'pointer',
    margin: theme.spacing(0, 3, 0, 5),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0),
    },
  },
  grow: {
    flexGrow: 1,
    height: '65px',
  },
  navbarColor: {
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: 'none',
    color: theme.palette.black,
  },
  title: {
    fontWeight: 'bold',
    cursor: 'pointer',
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <AppBar position="fixed" className={classes.navbarColor}>
        <Toolbar>
          <img
            src={Logo}
            className={classes.logo}
            onClick={() => history.push('/')}
          />
          <Typography
            className={classes.title}
            onClick={() => history.push('/')}
          >
            EOA Group
          </Typography>
          <div className={classes.grow} />
          <Clock />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default React.memo(Navbar);
