import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#000000";

const palette = {
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.pink[600],
    main: colors.pink[500],
    light: colors.pink[400],
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue["A400"],
    light: colors.blue["A400"],
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: "#F4F6F8",
    paper: white,
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  blueGray: {
    color50: "#F8FAFC",
    color100: "#F1F5F9",
    color200: "#E2E8F0",
    color300: "#CBD5E1",
    color400: "#94A3B8",
    color500: "#64748B",
    color600: "#475569",
    color700: "#334155",
    color800: "#1E293B",
    color900: "#0F172A",
  },
  coolGray: {
    color50: "#F9FAFB",
    color100: "#F3F4F6",
    color200: "#E5E7EB",
    color300: "#D1D5DB",
    color400: "#9CA3AF",
    color500: "#6B7280",
    color600: "#4B5563",
    color700: "#374151",
    color800: "#1F2937",
    color900: "#111827",
  },
  gray: {
    color50: "#FAFAFA",
    color100: "#F4F4F5",
    color200: "#E4E4E7",
    color300: "#D4D4D8",
    color400: "#A1A1AA",
    color500: "#71717A",
    color600: "#52525B",
    color700: "#3F3F46",
    color800: "#27272A",
    color900: "#18181B",
  },
  trueGray: {
    color50: "#FAFAFA",
    color100: "#F5F5F5",
    color200: "#E5E5E5",
    color300: "#D4D4D4",
    color400: "#A3A3A3",
    color500: "#737373",
    color600: "#525252",
    color700: "#404040",
    color800: "#262626",
    color900: "#171717",
  },
  warmGray: {
    color50: "#FAFAF9",
    color100: "#F5F5F4",
    color200: "#E7E5E4",
    color300: "#D6D3D1",
    color400: "#A8A29E",
    color500: "#78716C",
    color600: "#57534E",
    color700: "#44403C",
    color800: "#292524",
    color900: "#1C1917",
  },
  red: {
    color50: "#FEF2F2",
    color100: "#FEE2E2",
    color200: "#FECACA",
    color300: "#FCA5A5",
    color400: "#F87171",
    color500: "#EF4444",
    color600: "#DC2626",
    color700: "#B91C1C",
    color800: "#991B1B",
    color900: "#7F1D1D",
  },
  orange: {
    color50: "#FFF7ED",
    color100: "#FFEDD5",
    color200: "#FED7AA",
    color300: "#FDBA74",
    color400: "#FB923C",
    color500: "#F97316",
    color600: "#EA580C",
    color700: "#C2410C",
    color800: "#9A3412",
    color900: "#7C2D12",
  },
  amber: {
    color50: "#FFFBEB",
    color100: "#FEF3C7",
    color200: "#FDE68A",
    color300: "#FCD34D",
    color400: "#FBBF24",
    color500: "#F59E0B",
    color600: "#D97706",
    color700: "#B45309",
    color800: "#92400E",
    color900: "#78350F",
  },
  yellow: {
    color50: "#FEFCE8",
    color100: "#FEF9C3",
    color200: "#FEF08A",
    color300: "#FDE047",
    color400: "#FACC15",
    color500: "#EAB308",
    color600: "#CA8A04",
    color700: "#A16207",
    color800: "#854D0E",
    color900: "#713F12",
  },
  lime: {
    color50: "#F7FEE7",
    color100: "#ECFCCB",
    color200: "#D9F99D",
    color300: "#BEF264",
    color400: "#A3E635",
    color500: "#84CC16",
    color600: "#65A30D",
    color700: "#4D7C0F",
    color800: "#3F6212",
    color900: "#365314",
  },
  green: {
    color50: "#F0FDF4",
    color100: "#DCFCE7",
    color200: "#BBF7D0",
    color300: "#86EFAC",
    color400: "#4ADE80",
    color500: "#22C55E",
    color600: "#16A34A",
    color700: "#15803D",
    color800: "#166534",
    color900: "#14532D",
  },
  emerald: {
    color50: "#ECFDF5",
    color100: "#D1FAE5",
    color200: "#A7F3D0",
    color300: "#6EE7B7",
    color400: "#34D399",
    color500: "#10B981",
    color600: "#059669",
    color700: "#047857",
    color800: "#065F46",
    color900: "#064E3B",
  },
  teal: {
    color50: "#F0FDFA",
    color100: "#CCFBF1",
    color200: "#99F6E4",
    color300: "#5EEAD4",
    color400: "#2DD4BF",
    color500: "#14B8A6",
    color600: "#0D9488",
    color700: "#0F766E",
    color800: "#115E59",
    color900: "#134E4A",
  },
  cyan: {
    color50: "#ECFEFF",
    color100: "#CFFAFE",
    color200: "#A5F3FC",
    color300: "#67E8F9",
    color400: "#22D3EE",
    color500: "#06B6D4",
    color600: "#0891B2",
    color700: "#0E7490",
    color800: "#155E75",
    color900: "#164E63",
  },
  lightBlue: {
    color50: "#F0F9FF",
    color100: "#E0F2FE",
    color200: "#BAE6FD",
    color300: "#7DD3FC",
    color400: "#38BDF8",
    color500: "#0EA5E9",
    color600: "#0284C7",
    color700: "#0369A1",
    color800: "#075985",
    color900: "#0C4A6E",
  },
  blue: {
    color50: "#EFF6FF",
    color100: "#DBEAFE",
    color200: "#BFDBFE",
    color300: "#93C5FD",
    color400: "#60A5FA",
    color500: "#3B82F6",
    color600: "#2563EB",
    color700: "#1D4ED8",
    color800: "#1E40AF",
    color900: "#1E3A8A",
  },
  indigo: {
    color50: "#EEF2FF",
    color100: "#E0E7FF",
    color200: "#C7D2FE",
    color300: "#A5B4FC",
    color400: "#818CF8",
    color500: "#6366F1",
    color600: "#4F46E5",
    color700: "#4338CA",
    color800: "#3730A3",
    color900: "#312E81",
  },
  violet: {
    color50: "#F5F3FF",
    color100: "#EDE9FE",
    color200: "#DDD6FE",
    color300: "#C4B5FD",
    color400: "#A78BFA",
    color500: "#8B5CF6",
    color600: "#7C3AED",
    color700: "#6D28D9",
    color800: "#5B21B6",
    color900: "#4C1D95",
  },
  purple: {
    color50: "#FAF5FF",
    color100: "#F3E8FF",
    color200: "#E9D5FF",
    color300: "#D8B4FE",
    color400: "#C084FC",
    color500: "#A855F7",
    color600: "#9333EA",
    color700: "#7E22CE",
    color800: "#6B21A8",
    color900: "#581C87",
  },
  fuchsia: {
    color50: "#FDF4FF",
    color100: "#FAE8FF",
    color200: "#F5D0FE",
    color300: "#F0ABFC",
    color400: "#E879F9",
    color500: "#D946EF",
    color600: "#C026D3",
    color700: "#A21CAF",
    color800: "#86198F",
    color900: "#701A75",
  },
  pink: {
    color50: "#FDF2F8",
    color100: "#FCE7F3",
    color200: "#FBCFE8",
    color300: "#F9A8D4",
    color400: "#F472B6",
    color500: "#EC4899",
    color600: "#DB2777",
    color700: "#BE185D",
    color800: "#9D174D",
    color900: "#831843",
  },
  rose: {
    color50: "#FFF1F2",
    color100: "#FFE4E6",
    color200: "#FECDD3",
    color300: "#FDA4AF",
    color400: "#FB7185",
    color500: "#F43F5E",
    color600: "#E11D48",
    color700: "#BE123C",
    color800: "#9F1239",
    color900: "#881337",
  },
};

export default palette;
