import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';

import data from '../../data';
import { EntitasItem } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
    },
  },
  title: {
    fontSize: '25px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  boxEntitas: {
    marginTop: theme.spacing(5),
    justifyContent: 'center',
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Entitas EOA Group</Typography>
      <Grid container className={classes.boxEntitas}>
        {data.map((item) => (
          <Grid item lg={1} md={2} xs={6} key={item.id}>
            <EntitasItem data={item} />
          </Grid>
        ))}
      </Grid>
      <Box height="250px" />
    </div>
  );
};

export default Home;
