import palette from "./palette";

const typography = {
  h1: {
    fontWeight: 700,
    fontSize: "57px",
    lineHeight: "76px",
    fontFamily: "Poppins",
  },
  h2: {
    fontWeight: 700,
    fontSize: "47px",
    lineHeight: "63.69px",
    fontFamily: "Poppins",
  },
  // h3: {
  //   color: "#3D3D3D",
  //   fontWeight: 500,
  //   fontSize: "24px",
  //   lineHeight: "28px",
  //   fontFamily: "Encode Sans",
  // },
  h4: {
    color: "#3D3D3D",
    fontWeight: 700,
    fontFamily: "Poppins",
  },
  h5: {
    color: "#3D3D3D",
    fontWeight: 600,
    fontSize: "22px",
    fontFamily: "Poppins",
  },
  // h6: {
  //   color: "#3D3D3D",
  //   fontWeight: 500,
  //   fontSize: "14px",
  //   lineHeight: "20px",
  //   fontFamily: "Encode Sans",
  // },
  // subtitle1: {
  //   color: "#3D3D3D",
  //   fontSize: "16px",
  //   lineHeight: "25px",
  //   fontFamily: "Encode Sans",
  // },
  // subtitle2: {
  //   color: palette.text.secondary,
  //   fontWeight: 400,
  //   fontSize: "14px",
  //   lineHeight: "21px",
  //   fontFamily: "Encode Sans",
  // },
  body1: {
    // color: "#3D3D3D",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "23px",
    fontFamily: "Poppins",
    color: '#fff'
  },
  body2: {
    color: palette.text.secondary,
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily: "Poppins",
  },
  button: {
    color: "#3D3D3D",
    fontSize: "14px",
    fontFamily: "Poppins",
  },
  caption: {
    color: palette.text.secondary,
    fontSize: "11px",
    lineHeight: "13px",
    fontFamily: "Poppins",
  },
  // overline: {
  //   color: palette.text.secondary,
  //   fontSize: "11px",
  //   fontWeight: 500,
  //   lineHeight: "13px",
  //   textTransform: "uppercase",
  //   fontFamily: "Encode Sans",
  // },
};

export default typography;
