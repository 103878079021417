import LogoClub from '../assets/images/logo-club.png';
import LogoEHC from '../assets/images/logo-ehc.png';
import LogoEMS from '../assets/images/logo-ems.png';
import LogoGold from '../assets/images/logo-gold.png';
import LogoTech from '../assets/images/logo-tech.png';
import LogoWakaf from '../assets/images/logo-wakaf.png';
import LogoHealth from '../assets/images/logo-health.png';
import LogoBisnis from '../assets/images/logo-bisnis.png';
import LogoCDP from '../assets/images/logo-cdp.png';
import LogoMedia from '../assets/images/logo-media.jpeg';
import LogoOrganizer from '../assets/images/logo-ppa.png';

const data = [
  {
    id: 1,
    slug: 'eoa-gold',
    logo: LogoGold,
    name: 'EOA Gold',
    project: [
      {
        id: 1,
        name: 'POS GOLD',
        link: [
          'https://pos-pusat.eoatech.com/',
          'https://pos-cabang.eoatech.com/',
        ],
        description:
          'Website untuk membantu melakukan pencatatan Penjualan dan Buyback dan dapat membantu cabang untuk melakukan Transaksi PO dari Pusat.',
      },
      {
        id: 2,
        name: 'Registrasi AOG MOG',
        link: ['https://registrasi.eoagold.eoatech.com/'],
        description:
          'Website untuk melakukan pendaftaran Agent Of Gold dan Member Of Gold EOA Gold dan serta melakukan Upgrade ke Agent Of Gold dari Member Of Gold.',
      },
      {
        id: 3,
        name: 'EOA Gold Scanner',
        link: [
          'https://scanner.eoagold.eoatech.com',
          'https://play.google.com/store/apps/details?id=com.eoa.tech.international.goldscanner',
        ],
        description:
          'Sistem untuk menjaga dan menghadirkan kualitas optimal dari setiap kemasan EOA Gold yang akan di distribusikan kepada masyarakat luas',
      },
    ],
  },
  {
    id: 2,
    slug: 'eoa-club',
    logo: LogoClub,
    name: 'EOA Club',
    project: [
      {
        id: 1,
        name: 'E-Certificate',
        link: ['https://e-certificate.eoaclub.com/'],
        description:
          'Website untuk mengirimkan sertifikat keanggotaan yang sudah diterima menjadi bagian dari EOA Club',
      },
      {
        id: 2,
        name: 'EOA Chatbot',
        link: ['https://chatbot.eoatech.com/'],
        description:
          'Website yang membantu untuk mengenerate pesan yang nantinya akan dikirimkan ke akun-akun yang telah terdaftarkan',
      },
      {
        id: 3,
        name: 'EOA Club Setoran',
        link: [
          'https://setoranclub.eoatech.com/login',
          'https://setoranclub.eoatech.com/form-setoran',
        ],
        description:
          'Project yang akan dibangun adalah sistem setoran, EOA Club membutuhkan sistem ini untuk membuat proses bisnis mereka menjadi lebih efisien, oleh karena itu mereka membutuhkan sistem setoran agar bisa menunjang proses bisnis mereka. Project ini berbasis web based dimana nantinya ada sebuah form konfirmasi setoran yang akan digunakan member dan sebuah dashboard setoran yang akan memverifikasi setoran dari member',
      },
    ],
  },
  {
    id: 3,
    slug: 'wakaf-peradaban',
    logo: LogoWakaf,
    name: 'Wakaf Peradaban',
    project: [
      {
        id: 1,
        name: 'Manajemen Aset',
        link: ['https://ame.eoagroup.id/'],
        description: 'Website untuk memanajemen keberadaan aset-aset yang ada',
      },
      {
        id: 2,
        name: 'Wakaf Peradaban',
        link: [
          'https://bisawakaf.com/',
          'https://bisawakaf.com/4dm1n/login',
        ],
        description:
          'Project ini membangun aplikasi untuk menerima wakaf dari masyarakat umum. Aplikasi ini berbasis web. Ada 2 tampilan untuk website wakaf peradaban ini, yaitu tampilan user (yang ingin berwakaf) dan admin yang untuk memproses, mengapprove transaksi user.',
      },
    ],
  },
  {
    id: 4,
    slug: 'ems',
    logo: LogoEMS,
    name: 'EMS',
    project: [
      {
        id: 1,
        name: '1. Sistem Manajemen EOA',
        link: ['https://ms.eoatech.com'],
        description:
          'Project ini membangun aplikasi Manajemen sistem berbasis web. Aplikasi Manajemen sistem ini membantu user untuk mendefinisikan dan menampilkan Strength, Weakness, Opportunity, Threat (SWOT) dari entitasnya. Kemudian user dapat melakukan penilaian resiko dalam fitur Manajemen risiko. Fitur selanjutnya adalah OGSM, yang berfungsi untuk mendefinisikan Objective, Goals, Strategy dan Measures dari aktifitas entitas user. Sehingga dengan implementasi aplikasi Manajemen sistem user dapat memperoleh gambaran/peta jalan dalam pengambilan keputusan, pengembangan, penerapan, dan strategi perusahaan. Dalam aplikasi Manajemen sistim ini juga telah diimplementasikan struktur organisasi atau levelling dari user. Sehingga Manajemen sistem (SWOT, Manajemen Risk, OGSM) dapat ditampilkan secara berjenjang',
      },
    ],
  },
  {
    id: 5,
    slug: 'ehc',
    logo: LogoEHC,
    name: 'EHC',
    project: [
      {
        id: 1,
        name: 'HCIS',
        link: ['https://hcis.eoagroup.id/'],
        description:
          'Human Capital Information System ( HCIS )  sistem untuk mengelola berbagai kegiatan yang berkaitan dengan SDM peradaban EOA, melalui sistem HCIS yang tersentralisasi. Dengan HCIS, EHC dapat melacak berbagai informasi tentang karyawan, seperti data pribadi, izin cuti, raimbuse dan proses rekrutmen karyawan baru. Sistem HCIS ini berbasis web base.',
      },
    ],
  },
  {
    id: 6,
    slug: 'eoa-tech',
    logo: LogoTech,
    name: 'EOA Tech',
    project: [
      {
        id: 1,
        name: 'Company Profile',
        link: ['https://eoatech.com/'],
        description:
          'Website company profile untuk memperkenalkan apa itu EOA Tech',
      },
      {
        id: 2,
        name: 'JFOA Riyadoh',
        link: [
          'https://play.google.com/store/apps/details?id=dev.eoatech.yaumi',
        ],
        description: '',
      },
    ],
  },
  {
    id: 7,
    slug: 'ppa',
    logo: LogoOrganizer,
    name: 'PPA',
    project: [
      {
        id: 2,
        name: 'EOA Events',
        link: ['https://events.eoagroup.id/'],
        description:
          'Website untuk mengelola acara/event yang sedang atau diadakan oleh PT. EOA',
      },
    ],
  },
  {
    id: 8,
    slug: 'cdp',
    logo: LogoCDP,
    name: 'CDP',
    project: [
      {
        id: 1,
        name: 'Company Profile CDP',
        link: ['https://sbs.eoatech.com/'],
        description:
          'Website company profile untuk memperkenalkan tentang apa itu CDP',
      },
    ],
  },
  {
    id: 9,
    slug: 'health-care',
    logo: LogoHealth,
    name: 'Health Care',
    project: [
      {
        id: 1,
        name: 'EOA Health Care',
        link: ['https://healthcare.eoatech.com/login'],
        description:
          'Sistem untuk membuat generate id pada produk-produk herbal, generate id disini berfungsi agar produk-produk herbal berasal dari EOA Health Care tidak mudah ditiru dan barangnnya berasal dari Health Care.',
      },
    ],
  },
  {
    id: 10,
    slug: 'eoa-bisnis-forum',
    logo: LogoBisnis,
    name: 'EOA Bisnis Forum',
    project: [
      {
        id: 1,
        name: 'EOA Bisnis Forum',
        link: ['https://eoabisnisforum.com/'],
        description:
          'Website registrasi untuk mempertemukan antara calon Investor dan Bisnis Owner',
      },
    ],
  },
  {
    id: 11,
    slug: 'eoa-media',
    logo: LogoMedia,
    name: 'EOA Media',
    project: [
      {
        id: 1,
        name: 'EOA TV',
        link: ['https://tv.eoagroup.id/', 'https://tv.eoagroup.id/login-admin'],
        description:
          'Website platform untuk streaming kegiatan-kegiatan yang diadakan oleh Peradaban EOA. Sistem ini nantinya bisa menyediakan jadwal acara-acara, berita, diskusi. Aplikasi ini berbasis web base dan digunakan oleh user dan admin',
      },
    ],
  },
];
export default data;
