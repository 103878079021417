import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Navbar, Footer } from './components';
import Background from '../../assets/images/background.jpeg';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '65px',
  },
  absolute: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.032) 100%), linear-gradient(180deg, rgba(47, 171, 240, 0.32) 0%, rgba(19, 134, 200, 0.32) 100%)',
  },
  img: {
    width: '100%',
    height: '100%',
    filter: 'brightness(0.6);',
    objectFit: 'cover',
    display: 'block'
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className={classes.content}>{children}</div>
      <div>
        <Footer />
      </div>
      <div className={classes.absolute}>
        <img className={classes.img} src={Background} alt="background" />
      </div>
    </div>
  );
};

export default MainLayout;
