import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import data from '../../data';
import { ProjectItem } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
    },
  },
  title: {
    fontSize: '25px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  boxProject: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(0, 20),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 1),
    },
  },
}));

const DetailProject = () => {
  const classes = useStyles();
  const params = useParams();

  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const selected = data.find((x) => x.slug === params.name);
    setSelectedItem(selected);
  }, [params.name]);

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        Daftar Project {selectedItem?.name}
      </Typography>
      <div className={classes.boxProject}>
        {selectedItem?.project.map((item) => (
          <ProjectItem key={item.id} data={item} />
        ))}
      </div>
      <Box minHeight="200px" />
    </div>
  );
};

export default DetailProject;
