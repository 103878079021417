import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayoutNoToken } from '../components';

// Layout
import { Main as MainLayout } from '../layouts';

// Views
import { Home as HomeView, DetailProject as DetailProjectView } from '../views';

const Routes = () => {
  let route = (
    <Switch>
      <RouteWithLayoutNoToken
        component={HomeView}
        layout={MainLayout}
        path="/"
        exact
      />

      <RouteWithLayoutNoToken
        component={DetailProjectView}
        layout={MainLayout}
        path="/project/:name"
        exact
      />

      <Redirect to="/" />
    </Switch>
  );
  return route;
};

export default Routes;
