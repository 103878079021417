import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  craft: {
    fontSize: '14px',
    textAlign: 'center',
  },
  textEoa: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.craft}>
        Crafted with love for “Peradaban EOA”
      </Typography>
      <Typography className={classes.textEoa}>This Is EOA Lifestyle</Typography>
    </div>
  );
};

export default Footer;
