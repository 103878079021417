import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Typography,
  colors,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  boxAccord: {
    background: 'rgba(255, 255, 255, 0.24)',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  domain: {
    color: 'black',
  },
  link: {
    color: colors.blue[600],
    textDecoration: 'underline',
    cursor: 'pointer',
    marginBottom: theme.spacing(0.5),
  },
  boxLink: {
    marginBottom: theme.spacing(1),
  },
  heading: {
    color: 'black',
  },
}));

const ProjectItem = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.boxAccord}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{data.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div className={classes.boxLink}>
              {data.link.map((item, index) => (
                <Typography
                  className={classes.domain}
                  key={index}
                  onClick={() => window.open(item, '_blank')}
                >
                  Domain {data.link.length > 1 && index + 1}{' '}
                  <span className={classes.link}>{item}</span>
                </Typography>
              ))}
            </div>
            <Typography className={classes.heading}>
              {data.description}
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ProjectItem;
